import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import Helmet from "react-helmet";
import useSiteMetadata from "../meta";

const IndexPage = () => {
  const { phone, email, addres, ogrn, inn, kpp } = useSiteMetadata();
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "heliomotionpreview.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  return (
    <>
      <Helmet title="КФП - энерго" defer={false} />
      <div className="min-h-screen relative">
        <BackgroundImage Tag="section" {...bgImage}>
          <div style={{ minHeight: 1000, minWidth: 1000 }}>
            <div className="h-16 py-2 bg-black opacity-60 text-white">
              <div className="container mx-auto">
                <div className="flex space-x-8 justify-between">
                  <div>
                    <img src="https://kfpartners.ru/logo-footer.png" />
                  </div>
                  <div />
                  <div>
                    <div className="text-2xl font-semibold">{phone}</div>
                    <div className="text-xs text-zinc-200">
                      Ежедневно 09:00 - 18:00{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center h-96 my-40">
              <div className="space-y-16 max-w-4xl">
                <h1 className="text-5xl italic font-bold text-center fon">
                  Мы выводим солнечную энергетику на совершенно новый уровень
                </h1>
                <p className=" text-center text-xl italic">
                  Разработка, производство и сервисное обслуживание автономных
                  энергоустановок на солнечных панелях
                </p>
                <div className="grid grid-cols-3 gap-4 text-center">
                  <div className="flex flex-col">
                    <div className="mx-auto ">
                      <img
                        src="https://sunapex.com.ua/biz/img/top.svg"
                        className="self-center bg-center w-20 h-20"
                      />
                    </div>
                    <div className="bg-yellow-300 opacity-90 text-black my-4 p-4 rounded-xl">
                      <p>
                        Энергоустановка на солнечных панелях (ЭСП-3). Автономный
                        энергоблок, вырабатывающий электроэнергию до 80кВТ в
                        сутки.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="mx-auto ">
                      <img
                        src="https://sunapex.com.ua/biz/img/top2.svg"
                        className="self-center bg-center w-20 h-20"
                      />
                    </div>
                    <div className=" bg-yellow-300 opacity-90 text-black my-4 p-4 rounded-xl">
                      <p>
                        Система дистанционного управления установкой позволяет
                        настраивать солнечные панели, осуществлять мониторинг
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="mx-auto ">
                      <img
                        src="https://sunapex.com.ua/biz/img/top3.svg"
                        className="self-center bg-center w-20 h-20"
                      />
                    </div>
                    <div className=" bg-yellow-300 opacity-90 text-black my-4 p-4 rounded-xl">
                      <p>
                        Используется опытный образец, подтверждающий увеличение
                        получаемого электричества за счет автоматического
                        поворота панелей
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </div>
      <div className="mx-auto container max-w-6xl py-20 space-y-20">
        <div className="flex items-center space-x-8">
          <div className="w-2/3 space-y-4">
            <h2 className="text-4xl">Преимущества</h2>
            <p>
              Это инновационная высокоэффективная солнечная электростанция с
              двухосным отслеживанием для бытового и коммерческого
              использования.
            </p>
            <p>
              Имеет компактные размеры - менее 3.5 метров в высоту и менее 2
              метров в ширину. Обеспечивает проеобразование энергии и ее
              хранение с помощью аккумуляторных батарей с минимальным уровнем
              потерь КПД
            </p>
            <p>
              Является модульной, т.е. имеется возможность создания цепи из
              установок без существенных дополнительных затрат на обслуживающую
              инфраструктуру. Легко интегрируется с традиционными системами
              энергоснабжения.
            </p>
            <p>
              Установка состоит из двух идентичных блоков, включающих в себя:
              солнечные панели, повортоный редуктор с контроллерами, кожух с
              ячейками аккумуляторных батарей, инвертор, систему автоматов
              защиты, систему термостатирования (для использования в холодное
              время года){" "}
            </p>
          </div>
          <div className="w-1/3">
            <img
              src="https://heliomotion.com/app/uploads/pv-2000-circle-600x600@2x.jpg"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
            />
          </div>
        </div>
        <div className="flex items-center space-x-8">
          <div className="w-1/3">
            <img
              src="https://kfpartners.ru/images/graf.jpg"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
            />
          </div>
          <div className="w-1/3 space-y-4">
            <h2 className=" text-3xl">Максимальное производство энергии</h2>
            <p>
              Следуя за солнцем по двум осям и используя высокоэффективные
              солнечные панели, обеспечивает на 30-60% больше энергии в год, чем
              обычные солнечные панели, установленные на крыше, что обеспечивает
              более быструю окупаемость ваших инвестиций.
            </p>
          </div>
          <div className="w-1/3 space-y-4">
            <img
              src="https://heliomotion.com/app/uploads/360x400.gif"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
            />
          </div>
        </div>
        <div className="flex items-center space-x-8">
          <div className="w-2/3 space-y-4">
            <h2 className="text-4xl">Суть научной новизны продукта</h2>
            <p>
              ЭСП-3 представляет собой автономный энергоблок, вырабатывающий
              электроэнергию до 80кВТ в сутки. Система дистанционного управления
              установкой позволяет дистанционно настраивать стойки с солнечными
              панелями, осуществлять мониторинг за их работой и направлять
              данные о техническом состоянии ЭСП-3 диспетчеру, предупреждать
              возникновение неисправностей и принимать меры к их устранению, в
              том числе переходить на резервный источник питания потребителей.
            </p>
            <p>
              Собран и апробирован опытный образец, в результате чего были
              получены данные, подтверждающие увеличение получаемого
              электричества за счет автоматического поворота панелей к Солнцу.
              На сегодняшний день на стадии реализации этап корректировки ТЗ и
              эскизного проекта с учетом опыта эксплуатации опытного образца.
            </p>
          </div>
          <div className="w-1/3">
            <img
              src="https://heliomotion.com/app/uploads/pv-2000-circle-600x600@2x.jpg"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
            />
          </div>
        </div>
      </div>
      <div className="bg-slate-50 py-20 space-y-20">
        <div className="mx-auto container max-w-6xl">
          <h2 className=" text-center text-3xl font-bold pb-6">
            Энергоустановка на солнечных панелях (ЭСП-3)
          </h2>
          <div className="grid grid-cols-3 gap-8">
            <div className="bg-slate-100 rounded-lg">
              <img src="https://heliomotion.com/app/uploads/pv-3-hd-600x600.png" />
            </div>
            <div className="py-10 bg-slate-100 p-4 col-span-2">
              <h2 className="text-2xl font-bold pb-4">
                Технические характеристики:
              </h2>
              <div className="space-y-2">
                <ul className="mx-6 list-inside list-disc space-y-3 text-lg">
                  <li>
                    Автономная установка из 5 стоек, содержащих 30 солнечных
                    панелей, а также блока аккумуляторов и резервного источника
                    энергии, находящегося в термоконтейнере.
                  </li>
                  <li>
                    Количество вырабатываемой энергии в месяц (июль) – 2 480кВт
                  </li>
                  <li>
                    Возможность дистанционного мониторинга и управления –
                    имеется
                  </li>
                  <li>
                    Установка и автономная работа в труднодоступных местах -
                    возможна
                  </li>
                  <li>Суммарная мощность 10 кВТ*ч</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto container max-w-6xl py-20 space-y-2">
        <p className="text-2xl">
          <strong>Здравствуйте!</strong>
        </p>
        <p>
          Я рад приветствовать Вас на сайте ООО «КФП-Энерго». Наша компания
          занимается разработкой, производством и сервисным обслуживанием
          автономных энергоустановок на солнечных панелях.
        </p>
        <p>
          Изначально идея их создания базировалась на уже получившей свое
          развитие в Европе шведской технологии, которая заключается в повороте
          стойки с солнечными панелями вслед за движением Солнца. Однако при
          испытаниях опытного образца с использованием шведских микросхем мы
          столкнулись с рядом проблем.
        </p>
        <p>
          В их числе следует отметить необходимость ручной настройки каждой
          микросхемы, невозможностью дистанционного управления, а также
          получения информации о состоянии системы и, что особенно актуально в
          нынешней социально-экономической ситуации, с большим количеством
          зарубежных комплектующих.
        </p>
        <p>
          В связи с этим нашей командой инженеров, большинство из которых имеют
          опыт работы на предприятиях военно-промышленного комплекса, была
          разработана принципиально новая энергоустановка. Она состоит из
          необходимого числа стоек с солнечными панелями отечественного
          производства, которые поворачиваются с помощью механизма,
          разработанного учеными Марийского политехнического университета, и
          оригинальных микросхем, а также термоконтейнера отечественного
          производства в котором располагаются российские аккумуляторы, инвертор
          и аварийный источник энергии.
        </p>
        <p>
          В базовой комплектации установка вырабатывает около 80кВТ энергии в
          сутки, что достаточно для обеспечения электричеством нескольких
          частных домов. Инновацией нашей установки является возможность
          мониторинга и управления ею с помощью специального приложения. Следует
          честно сказать, что наша разработка не является альтернативой
          электрическим сетям – ее использование экономически выгодно там, где
          проложить кабель невозможно или очень дорого.
        </p>
        <p>
          Поэтому мы предлагаем наш продукт государственным организациям,
          участвующим в ликвидации чрезвычайных происшествий, сотовым
          операторам, компаниям, занимающимся экотуризмом, сельским хозяйством,
          всем кто заинтересован в стабильном источнике энергии вне зависимости
          от внешних факторов.
        </p>
        <p>
          Наши специалисты рассчитают оптимальную структуру энергоустановки,
          проведут монтаж и организуют ее сервисное обслуживание. Мы всегда
          готовы быть полезными Вам.
        </p>
      </div>
      <div className="bg-slate-600 py-20 space-y-20">
        <div className="mx-auto container max-w-7xl">
          <div className="grid grid-cols-4 gap-8">
            <div className="space-y-4">
              <img src="https://kfpartners.ru/logo-footer.png" />
              <div className="text-gray-400 space-y-2">
                <p>
                  График работы:
                  <br />{" "}
                  <span className="text-gray-300">Пн-Пт: 09.00 – 18.00</span>
                </p>
              </div>
            </div>
            <div className="text-gray-400 col-span-2 space-y-3">
              <p className="text-gray-300 font-semibold text-lg">
                Группа компаний «Красильников, Фролов и партнеры»
              </p>
              <p>
                Адрес компании: <span className="text-gray-300">{addres}</span>
              </p>
              <p>
                Тел: {phone} E-mail: {email}
              </p>
            </div>
            <div className="text-gray-400 space-y-3">
              <p className="text-gray-300 font-semibold text-lg">
                ООО «КФП-ЭНЕРГО»
              </p>{" "}
              <p>ОГРН: {ogrn}</p>
              <p>
                ИНН/КПП: {inn}/{kpp}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
